import React, {useEffect} from 'react'
import {Html5Qrcode} from 'html5-qrcode'

type QrScannerProps = {
  onScanSuccess: (decodedText: string) => void
  onScanError: (errorMessage: string) => void
  onClose: () => void
}

const QrScanner: React.FC<QrScannerProps> = ({onScanSuccess, onScanError, onClose}) => {
  useEffect(() => {
    const html5QrCode = new Html5Qrcode('reader')
    let isScannerRunning = false
    let hasScanned = false // 추가된 플래그 변수

    const config = {
      fps: 10,
      qrbox: {width: 250, height: 250},
      aspectRatio: 1.7777778
    }

    const handleScanSuccess = (decodedText: string, decodedResult: any) => {
      if (!hasScanned) {
        hasScanned = true // 스캔 완료 표시
        html5QrCode
          .stop()
          .then(() => {
            isScannerRunning = false
            onScanSuccess(decodedText) // 성공 콜백 호출
          })
          .catch(err => {
            console.error('스캐너 중지 오류:', err)
          })
      }
    }

    html5QrCode
      .start({facingMode: 'environment'}, config, handleScanSuccess, errorMessage => {
        // 스캔 오류 처리 (예: QR 코드 인식 실패)
        console.error('QR 스캔 오류:', errorMessage)
      })
      .then(() => {
        isScannerRunning = true
      })
      .catch(err => {
        // 카메라 접근 권한 거부 또는 오류 처리
        console.error('QR 스캐너 시작 오류:', err)
        onScanError('카메라에 접근할 수 없습니다. 권한을 확인해주세요.')
        onClose() // 스캐너 닫기
      })

    return () => {
      if (isScannerRunning) {
        html5QrCode.stop().catch(err => {
          console.error('스캔 중지 실패.', err)
        })
      }
    }
  }, [onScanSuccess, onScanError, onClose])

  const handleClose = () => {
    onClose()
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden',
        zIndex: 1000
      }}>
      {/* 카메라 프리뷰 */}
      <div id="reader" style={{width: '100%', height: '100%'}} />
      {/* 오버레이 */}
      {/* 상단 오버레이 */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 'calc(50% + 125px)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }}
      />
      {/* 하단 오버레이 */}
      <div
        style={{
          position: 'absolute',
          top: 'calc(50% + 125px)',
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }}
      />
      {/* 좌측 오버레이 */}
      <div
        style={{
          position: 'absolute',
          top: 'calc(50% - 125px)',
          left: 0,
          right: 'calc(50% + 125px)',
          height: '250px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }}
      />
      {/* 우측 오버레이 */}
      <div
        style={{
          position: 'absolute',
          top: 'calc(50% - 125px)',
          left: 'calc(50% + 125px)',
          right: 0,
          height: '250px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }}
      />
      {/* 스캔 영역 테두리 */}
      <div
        style={{
          position: 'absolute',
          top: 'calc(50% - 125px)',
          left: 'calc(50% - 125px)',
          width: '250px',
          height: '250px',
          border: '2px solid white',
          boxSizing: 'border-box'
        }}
      />
      {/* 닫기 버튼 */}
      <button
        onClick={handleClose}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          padding: '10px',
          backgroundColor: 'red',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer'
        }}>
        닫기
      </button>
    </div>
  )
}

export default QrScanner

// src/api/axios.ts
import axios from 'axios'

// Axios 인스턴스 생성
const axiosInstance = axios.create({
  baseURL: 'https://api.makesomenoise.kr', // 기본 API URL 설정
  withCredentials: true, // 쿠키를 포함한 요청을 보내기 위해 설정
  headers: {
    'Content-Type': 'application/json', // 기본 콘텐츠 타입 설정
    Accept: 'application/json' // 서버로부터 받을 응답 타입 설정
  },
  timeout: 10000 // 요청 타임아웃 설정 (밀리초 단위)
})

// 요청 인터셉터: 모든 요청에 JWT 토큰을 추가
axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 응답 인터셉터 (선택 사항)
axiosInstance.interceptors.response.use(
  response => {
    // 응답 데이터를 가공하거나 처리할 수 있습니다.
    return response
  },
  error => {
    // 응답 오류가 있는 경우 수행할 작업
    if (error.response) {
      // 서버가 상태 코드로 응답했지만 요청이 실패한 경우
      switch (error.response.status) {
        case 401:
          // 예: 인증이 필요한 경우 로그인 페이지로 리디렉션
          // window.location.href = '/login';
          break
        case 403:
          // 예: 권한이 없는 경우
          alert('권한이 없습니다.')
          break
        case 404:
          alert('요청하신 리소스를 찾을 수 없습니다.')
          break
        case 500:
          alert('서버 오류가 발생했습니다. 나중에 다시 시도해주세요.')
          break
        default:
          alert('알 수 없는 오류가 발생했습니다.')
      }
    } else if (error.request) {
      // 요청이 이루어졌으나 응답을 받지 못한 경우
      alert('서버와의 연결이 끊겼습니다. 인터넷 연결을 확인해주세요.')
    } else {
      // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생한 경우
      alert('요청 설정 중 오류가 발생했습니다.')
    }
    return Promise.reject(error)
  }
)

export default axiosInstance

import {atom} from 'recoil'

export interface User {
  user_id: number
  name: string
  contact: string
  course: 'A' | 'B'
  remarks: string
}

export interface Checkpoint {
  checkpoint_id: number
  course: 'A' | 'B'
  sequence_number: number
  longitude: number
  latitude: number
  name: string
  display_name: string
}

export interface Authentication {
  checkpoint_id: number
  authentication_time: string
}

export interface Prize {
  prize_id: number
  brand: string
  item: string
  option: string | null
  winner_user_id: number | null
  received: boolean
  remarks: string
}

// 인증 상태 아톰
export const authState = atom<boolean>({
  key: 'authState',
  default: false
})

// 사용자 정보 아톰
export const userState = atom<User | null>({
  key: 'userState',
  default: null
})

// 체크포인트 목록 아톰
export const checkpointsState = atom<Checkpoint[]>({
  key: 'checkpointsState',
  default: []
})

// 인증된 체크포인트 목록 아톰
export const authenticatedCheckpointsState = atom<Checkpoint[]>({
  key: 'authenticatedCheckpointsState',
  default: []
})

// 인증 정보 아톰
export const userAuthenticationsState = atom<Authentication[]>({
  key: 'userAuthenticationsState',
  default: []
})

// 경품 목록 아톰
export const prizesState = atom<Prize[]>({
  key: 'prizesState',
  default: []
})

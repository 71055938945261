// src/App.tsx
import React from 'react'
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import {RecoilRoot, useRecoilValue} from 'recoil'
import Login from './components/Login'
import Map from './components/Map'
import Onboarding from './components/Onboarding'
import 'mapbox-gl/dist/mapbox-gl.css'
// import Onboarding from './components/Onboarding'
// import MapScreen from './components/MapScreen'
// import Prizes from './components/Prizes'
import {authState} from './state/atoms'

const AppRoutes: React.FC = () => {
  const isLoggedIn = useRecoilValue(authState)

  return (
    <Routes>
      <Route path="/" element={isLoggedIn ? <Navigate to="/map" /> : <Login />} />
      <Route path="/map" element={isLoggedIn ? <Map /> : <Navigate to="/" />} />
      <Route path="/onboarding" element={<Onboarding />} />
      <Route path="*" element={isLoggedIn ? <Navigate to="/map" /> : <Login />} />
      {/* <Route
        path="/onboarding"
        element={isLoggedIn ? <Onboarding /> : <Navigate to="/" />}
      />
      <Route path="/map" element={isLoggedIn ? <MapScreen /> : <Navigate to="/" />} />
      <Route path="/prizes" element={isLoggedIn ? <Prizes /> : <Navigate to="/" />} /> */}
      {/* 기타 라우트 추가 */}
    </Routes>
  )
}

const App: React.FC = () => {
  return (
    <RecoilRoot>
      <Router>
        <AppRoutes />
      </Router>
    </RecoilRoot>
  )
}

export default App

import React, {useState} from 'react'
import axiosInstance from '../api/axios' // 커스텀 Axios 인스턴스
import {useNavigate} from 'react-router-dom'
import {useSetRecoilState} from 'recoil'
import {authState, userState, userAuthenticationsState} from '../state/atoms'
import logo from '../assets/image/bg-remove4.png' // 로고 이미지
import './Login.css' // 스타일링 파일

const Login: React.FC = () => {
  const [name, setName] = useState('')
  const [participantNumber, setParticipantNumber] = useState('')
  const [error, setError] = useState('')
  const setAuth = useSetRecoilState(authState)
  const setUser = useSetRecoilState(userState)
  const setUserAuthentications = useSetRecoilState(userAuthenticationsState) // 인증 정보 상태 설정 함수
  const navigate = useNavigate()

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault()

    // 입력 검증
    if (!name || !participantNumber) {
      setError('이름과 참가자 번호를 입력해주세요.')
      return
    }

    // 참가자 번호의 마지막 4자리 추출
    const lastFourContact = participantNumber.slice(-4)

    try {
      const response = await axiosInstance.post('/login', {
        name,
        lastFourContact
      })

      if (response.data.success) {
        setAuth(true)
        setUser(response.data.user)
        setUserAuthentications(response.data.authentications) // 인증 정보 설정
        // console.log(response.data.authentications)
        localStorage.setItem('token', response.data.token) // JWT 저장

        console.log(response.data.user.is_logged_in)

        if (!response.data.user.is_logged_in) {
          await axiosInstance.post('/setLogin', {
            userId: response.data.user.user_id,
            isLoggedIn: true
          })

          navigate('/map')
          // navigate('/onboarding') // 온보딩이 필요한 경우 온보딩 페이지로 이동
        } else {
          navigate('/map') // 온보딩 완료된 사용자는 메인 페이지로 이동
        }
      } else {
        setError(response.data.message || '로그인 실패')
      }
    } catch (error) {
      console.error('로그인 오류:', error)
      setError('로그인 중 오류가 발생했습니다.')
    }
  }

  return (
    <div style={styles.container}>
      <img
        className="logo"
        src={logo}
        alt="팔금도 트래킹 대회 로고"
        style={styles.logo}
      />

      <div style={styles.loginBox}>
        <h2 style={styles.title}>메이크 섬 노이즈 행사 참여</h2>
        <form onSubmit={handleLogin}>
          <div style={styles.inputWrapper}>
            <label style={styles.label}>이름</label>
            <input
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              style={styles.input}
              required
            />
          </div>
          <div style={styles.inputWrapper}>
            <label style={styles.label}>전화번호 마지막 4자리</label>
            <input
              type="text"
              value={participantNumber}
              onChange={e => setParticipantNumber(e.target.value)}
              style={styles.input}
              required
            />
          </div>
          {error && <div style={styles.error}>{error}</div>}
          <button type="submit" style={styles.button}>
            로그인
          </button>
        </form>
      </div>
    </div>
  )
}

// 스타일링 유지
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    // backgroundImage: 'url("/path/to/your/background-image.jpg")', // 백그라운드 이미지
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  loginBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // 살짝 투명한 흰색 배경
    padding: '20px',
    borderRadius: '10px',
    width: '90%',
    maxWidth: '400px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
  },
  title: {
    marginBottom: '20px',
    fontSize: '1.5rem',
    color: '#333'
  },
  inputWrapper: {
    marginBottom: '15px',
    marginRight: '15px'
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontSize: '1rem',
    color: '#555'
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '1rem',
    borderRadius: '5px',
    border: '1px solid #ccc'
  },
  button: {
    width: '100%',
    padding: '10px',
    fontSize: '1rem',
    color: '#fff',
    backgroundColor: '#4CAF50', // 로그인 버튼의 녹색
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '10px'
  },
  error: {
    color: 'red',
    marginBottom: '15px'
  },
  logo: {
    maxWidth: '300px',
    height: 'auto',
    display: 'block',
    margin: '0 auto',
    marginTop: '10%',
    marginBottom: '20%'
  }
}

export default Login

// src/components/Map.tsx
import React, {useEffect, useState, useRef} from 'react'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {userState, userAuthenticationsState} from '../state/atoms'
import mapboxgl from 'mapbox-gl'
import MapboxLanguage from '@mapbox/mapbox-gl-language'
import a_cp from '../data/a_cp.json'
import b_cp from '../data/b_cp.json'
import {Checkpoint} from '../types/Checkpoint'
import './Map.css'
import QrScanner from './QrScanner'
import {FaQrcode} from 'react-icons/fa'
import axiosInstance from '../api/axios'
import Modal from 'react-modal'

import courseA from '../data/a.json'
import courseB from '../data/b.json'

import sosImage from '../assets/image/sos.png'

if (process.env.REACT_APP_MAPBOX_ACCESS_TOKEN) {
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
} else {
  console.error('Mapbox access token is not defined.')
}

const Map: React.FC = () => {
  const mapContainerRef = React.useRef<HTMLDivElement>(null)
  const mapRef = React.useRef<mapboxgl.Map | null>(null)
  const user = useRecoilValue(userState)
  const setUserAuthentications = useSetRecoilState(userAuthenticationsState)
  const [mapLoaded, setMapLoaded] = useState(false)

  const [checkpoints, setCheckpoints] = useState<Checkpoint[]>([])
  const [showQrScanner, setShowQrScanner] = useState(false)
  const [currentLocation, setCurrentLocation] = useState<{
    longitude: number
    latitude: number
  } | null>(null)
  const [loadingGps, setLoadingGps] = useState(false)
  const [errorGps, setErrorGps] = useState<string | null>(null)
  const [markers, setMarkers] = useState<mapboxgl.Marker[]>([]) // 마커 상태 관리
  const [selectedCP, setSelectedCP] = useState<Checkpoint | null>(null) // 선택된 CP 상태
  const [showCompletionModal, setShowCompletionModal] = useState(false) // 모든 CP 인증 완료 모달 상태

  const hasScannedRef = useRef(false)
  const userAuthentications = useRecoilValue(userAuthenticationsState)

  const [cameraStream, setCameraStream] = useState<MediaStream | null>(null)

  const [prizeMessage, setPrizeMessage] = useState<string | null>(null)
  useEffect(() => {
    if (showCompletionModal && user) {
      // showCompletionModal이 true로 설정되었을 때 API 호출
      axiosInstance
        .post('/checkUserPrize', {userId: user.user_id})
        .then(response => {
          if (response.status === 200 && response.data.prizeMessage) {
            setPrizeMessage(response.data.prizeMessage)
          } else {
            setPrizeMessage(null) // 당첨 경품이 없을 때
          }
        })
        .catch(error => {
          console.error('경품 정보 확인 중 오류 발생:', error)
          setPrizeMessage(null)
        })
    }
  }, [showCompletionModal, user])

  const [showSOSModal, setShowSOSModal] = useState(false)
  const [isSendingSOS, setIsSendingSOS] = useState(false)
  const [sosError, setSosError] = useState<string | null>(null)
  /*
  // * SOS 버튼 클 시 현재 위치 가져오기
  const handleSOSClick = () => {
    // 현재 위치 가져오기
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const {longitude, latitude} = position.coords
          setCurrentLocation({longitude, latitude})
          setShowSOSModal(true)
        },
        error => {
          console.error('현재 위치를 가져오는 데 실패했습니다:', error)
          alert('현재 위치를 가져오는 데 실패했습니다.')
        },
        {enableHighAccuracy: true}
      )
    } else {
      alert('이 브라우저는 위치 정보를 지원하지 않습니다.')
    }
  }

  // SOS 전송 확인
  const handleConfirmSOS = () => {
    if (!currentLocation) {
      alert('현재 위치 정보가 없습니다.')
      return
    }

    setIsSendingSOS(true)
    setSosError(null)

    // 여기서 실제 SOS 전송 로직을 구현합니다.
    // 예를 들어, 서버에 POST 요청을 보내는 등의 작업을 수행할 수 있습니다.
    // 여기서는 예시로 setTimeout을 사용하여 전송 과정을 시뮬레이션합니다.

    setTimeout(() => {
      // 성공적으로 전송된 경우
      setIsSendingSOS(false)
      setShowSOSModal(false)
      alert('응급 상황이 성공적으로 전송되었습니다.')
      // 실제로는 서버 응답에 따라 처리해야 합니다.
    }, 2000)
  }

  // SOS 전송 취소
  const handleCancelSOS = () => {
    setShowSOSModal(false)
  }
    */
  // 현재 위치 가져오기
  const handleSOSClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const {longitude, latitude} = position.coords
          setCurrentLocation({longitude, latitude})
          setShowSOSModal(true)
        },
        error => {
          console.error('현재 위치를 가져오는 데 실패했습니다:', error)
          alert('현재 위치를 가져오는 데 실패했습니다.')
        },
        {enableHighAccuracy: true}
      )
    } else {
      alert('이 브라우저는 위치 정보를 지원하지 않습니다.')
    }
  }

  // SOS 전송 확인
  const handleConfirmSOS = async () => {
    if (!currentLocation) {
      alert('현재 위치 정보가 없습니다.')
      return
    }

    setIsSendingSOS(true)
    setSosError(null)

    try {
      const response = await axiosInstance.post('/sos', {
        name: user?.name, // 사용자 이름
        phoneNumber: user?.contact, // 사용자 전화번호
        location: currentLocation // 사용자 위치 { latitude, longitude }
        // latitude: currentLocation.latitude,
        // longitude: currentLocation.longitude
      })

      if (response.status === 200) {
        alert('응급 상황이 성공적으로 전송되었습니다.')
      } else {
        alert('응급 상황 전송에 실패했습니다.')
      }
    } catch (error) {
      console.error('SOS 전송 중 오류 발생:', error)
      alert('SOS 전송에 실패했습니다. 다시 시도해 주세요.')
    } finally {
      setIsSendingSOS(false)
      setShowSOSModal(false)
    }
  }

  // SOS 전송 취소
  const handleCancelSOS = () => {
    setShowSOSModal(false)
  }

  // * 지도 초기화
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current as HTMLDivElement,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [126.14388696, 34.784798668],
      zoom: 12,
      attributionControl: false
    })

    mapRef.current = map

    const language = new MapboxLanguage({defaultLanguage: 'ko'})
    map.addControl(language)

    // GeolocateControl 추가
    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
      showUserHeading: true
    })
    map.addControl(geolocate, 'bottom-right')

    // ! 식사 아이콘, 화장실 아이콘 추가
    const mealMarker = document.createElement('div')
    mealMarker.className = 'marker'
    mealMarker.style.backgroundImage = 'url(assets/icon/meal.png)'
    mealMarker.style.width = '25px'
    mealMarker.style.height = '25px'
    mealMarker.style.backgroundSize = '100%'

    // 마커 추가
    new mapboxgl.Marker(mealMarker)
      .setLngLat([126.1084, 34.78329283]) // 원하는 좌표로 변경
      .setPopup(
        new mapboxgl.Popup({offset: 25}) // 팝업 추가 (선택 사항)
          .setText('Meal Location')
      )
      .addTo(map)

    // * 화장실1 아이콘 추가 (시작점)
    const toilet1 = document.createElement('div')
    toilet1.className = 'marker'
    toilet1.style.backgroundImage = 'url(assets/icon/toilet.png)'
    toilet1.style.width = '25px'
    toilet1.style.height = '25px'
    toilet1.style.backgroundSize = '100%'

    // 마커 추가
    new mapboxgl.Marker(toilet1)
      .setLngLat([126.14395, 34.78455]) // 원하는 좌표로 변경
      .setPopup(
        new mapboxgl.Popup({offset: 25}) // 팝업 추가 (선택 사항)
          .setText('toilet')
      )
      .addTo(map)

    // * 화장실2 아이콘 추가 (시작점)
    const toilet2 = document.createElement('div')
    toilet2.className = 'marker'
    toilet2.style.backgroundImage = 'url(assets/icon/toilet.png)'
    toilet2.style.width = '25px'
    toilet2.style.height = '25px'
    toilet2.style.backgroundSize = '100%'

    // 마커 추가
    new mapboxgl.Marker(toilet2)
      .setLngLat([126.126299, 34.78946]) // 원하는 좌표로 변경
      .setPopup(
        new mapboxgl.Popup({offset: 25}) // 팝업 추가 (선택 사항)
          .setText('toilet')
      )
      .addTo(map)

    // * 화장실3 아이콘 추가 (시작점)
    const toilet3 = document.createElement('div')
    toilet3.className = 'marker'
    toilet3.style.backgroundImage = 'url(assets/icon/toilet.png)'
    toilet3.style.width = '25px'
    toilet3.style.height = '25px'
    toilet3.style.backgroundSize = '100%'

    // 마커 추가
    new mapboxgl.Marker(toilet3)
      .setLngLat([126.120339, 34.786095]) // 원하는 좌표로 변경
      .setPopup(
        new mapboxgl.Popup({offset: 25}) // 팝업 추가 (선택 사항)
          .setText('toilet')
      )
      .addTo(map)

    if (user?.course === 'B') {
      const toilet4 = document.createElement('div')
      toilet4.className = 'marker'
      toilet4.style.backgroundImage = 'url(assets/icon/toilet.png)'
      toilet4.style.width = '25px'
      toilet4.style.height = '25px'
      toilet4.style.backgroundSize = '100%'

      // 마커 추가
      new mapboxgl.Marker(toilet4)
        .setLngLat([126.098842, 34.783882]) // 원하는 좌표로 변경
        .setPopup(
          new mapboxgl.Popup({offset: 25}) // 팝업 추가 (선택 사항)
            .setText('toilet')
        )
        .addTo(map)
    }

    // !

    map.on('load', () => {
      console.log('Map loaded')
      setMapLoaded(true)
    })

    return () => {
      map.remove()
    }
  }, [])

  // * 사용자 코스에 따른 CP 데이터 설정
  useEffect(() => {
    if (!user || !mapLoaded) return
    if (!mapRef.current) return

    // ! 회색 루트 그리기
    if (user.course === 'A') {
      mapRef.current.addSource('route', {
        type: 'geojson',
        data: courseA as GeoJSON.FeatureCollection<GeoJSON.Geometry>
      })

      mapRef.current.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': ['get', 'color'],
          'line-width': 4
        }
      })
    } else if (user.course === 'B') {
      mapRef.current.addSource('route', {
        type: 'geojson',
        data: courseB as GeoJSON.FeatureCollection<GeoJSON.Geometry>
      })

      mapRef.current.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': ['get', 'color'],
          'line-width': 4
        }
      })
    }

    const rawCheckpoints: any[] = user.course === 'A' ? a_cp : b_cp

    const tmpCheckpoints: Checkpoint[] = rawCheckpoints.map(cp => ({
      ...cp,
      coordinates: [cp.coordinates[0], cp.coordinates[1]] as [number, number]
    }))

    setCheckpoints(tmpCheckpoints)
    console.log('Set Checkpoints:', checkpoints)
  }, [user, mapLoaded])

  // * 마커 추가
  useEffect(() => {
    if (!mapRef.current || checkpoints.length === 0) {
      return
    }

    markers.forEach(marker => marker.remove())
    setMarkers([])

    const newMarkers: mapboxgl.Marker[] = checkpoints.map(cp => {
      const isAuthenticated = userAuthentications.some(
        auth => auth.checkpoint_id === cp.index
      )

      const el = document.createElement('div')
      el.className = 'marker'
      if (isAuthenticated) {
        el.classList.add('authenticated')
        el.style.backgroundImage = `url(/assets/icon/${cp.image_src.replace(
          '.png',
          '_pass.png'
        )})`
      } else {
        el.style.backgroundImage = `url(/assets/icon/${cp.image_src})`
      }

      el.style.width = '40px'
      el.style.height = '40px'
      el.style.backgroundSize = '100%'

      const marker = new mapboxgl.Marker(el)
        .setLngLat(cp.coordinates)
        .setPopup(new mapboxgl.Popup({offset: 25}).setHTML(`<h3>${cp.displayLabel}</h3>`))
        .addTo(mapRef.current as mapboxgl.Map)

      marker.getElement().addEventListener('click', () => {
        setSelectedCP(cp)
      })

      console.log('Marker added:', cp.displayLabel)

      return marker
    })

    setMarkers(newMarkers)
  }, [checkpoints, userAuthentications])

  // * checkpoints 업데이트 후 로그 확인용 useEffect 추가
  useEffect(() => {
    console.log('Updated Checkpoints:', checkpoints)
  }, [checkpoints])

  // * 루트 그리기
  const drawRoute = (start: number, end: number) => {
    if (!user) return

    const routePath =
      user.course === 'A'
        ? `/routes/a_${start}_${end}.json`
        : `/routes/b_${start}_${end}.json`
    // const routePath = `/routes/a_${start}_${end}.json`

    fetch(routePath)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to load route data')
        }
        return response.json()
      })
      .then(jsonData => {
        const lineCoordinates = jsonData.features[0].geometry.coordinates

        const sortedCoordinates = [...lineCoordinates]

        if (mapRef.current) {
          const routeId = `route_${start}_${end}`

          if (!mapRef.current.getSource(routeId)) {
            mapRef.current.addSource(routeId, {
              type: 'geojson',
              data: {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates: sortedCoordinates
                }
              }
            })

            mapRef.current.addLayer({
              id: routeId,
              type: 'line',
              source: routeId,
              layout: {
                'line-join': 'round',
                'line-cap': 'round'
              },
              paint: {
                'line-color': '#FFA500',
                'line-width': 4
              }
            })
          }
        }
      })
      .catch(error => {
        console.error(`Error loading route from ${routePath}:`, error)
      })
  }

  useEffect(() => {
    if (!mapLoaded) return
    if (!user) return

    userAuthentications.forEach((auth, index) => {
      if (index < userAuthentications.length - 1) {
        const start = auth.checkpoint_id
        const end = userAuthentications[index + 1].checkpoint_id
        drawRoute(start, end)
      }
    })

    if (user.course === 'A' && userAuthentications.length === a_cp.length) {
      setShowCompletionModal(true)
    } else if (user.course === 'B' && userAuthentications.length === b_cp.length) {
      setShowCompletionModal(true)
    }
  }, [mapLoaded, userAuthentications, user])

  // * QR 인증 버튼 클릭 핸들러
  // const [cameraStream, setCameraStream] = useState<MediaStream | null>(null)

  const handleQrAuthClick = () => {
    setLoadingGps(true)
    setErrorGps(null)

    if (!navigator.geolocation) {
      alert('GPS를 지원하지 않는 기기입니다.')
      setErrorGps('GPS를 지원하지 않는 기기입니다.')
      setLoadingGps(false)
      return
    }

    navigator.geolocation.getCurrentPosition(
      position => {
        setLoadingGps(false)
        setCurrentLocation({
          longitude: position.coords.longitude,
          latitude: position.coords.latitude
        })

        navigator.mediaDevices
          .getUserMedia({video: {facingMode: 'environment'}})
          .then(stream => {
            setCameraStream(stream) // 스트림 상태 저장
            setShowQrScanner(true)
          })
          .catch(err => {
            console.error('카메라 접근 오류:', err)
            alert('카메라 접근에 문제가 있습니다.')
          })
      },
      error => {
        setLoadingGps(false)
        console.error('GPS 오류:', error)
        setErrorGps('GPS를 사용할 수 없습니다. 권한을 확인해주세요.')
      }
    )
  }

  const handleCloseScanner = () => {
    setShowQrScanner(false)
    hasScannedRef.current = false

    // 카메라 스트림 중지
    if (cameraStream) {
      cameraStream.getTracks().forEach(track => track.stop())
      setCameraStream(null)
    }
  }

  // * QR 스캔 성공 핸들러
  const handleScanSuccess = async (decodedText: string) => {
    if (hasScannedRef.current) return
    hasScannedRef.current = true

    if (checkpoints.length === 0) {
      alert('정보 로드 중입니다.')
      setShowQrScanner(false)
      hasScannedRef.current = false
      return
    }

    if (!user) {
      return
    }

    try {
      const cpIndex = checkpoints.findIndex(cp => cp.label === decodedText)

      if (cpIndex !== -1) {
        const cp = checkpoints[cpIndex]
        if (cp.authenticatedAt) {
          alert(`이미 인증된 CP입니다: ${cp.displayLabel}`)
        } else {
          try {
            const response = await axiosInstance.post('/qr', {
              userId: user.user_id,
              userCourse: user.course,
              label: cp.label,
              latitude: currentLocation?.latitude,
              longitude: currentLocation?.longitude
            })
            const data = response.data

            if (response.status === 200) {
              const updatedCp = {
                ...cp,
                authenticatedAt: data.authenticatedAt || new Date().toISOString()
              }
              const updatedCheckpoints = [...checkpoints]
              updatedCheckpoints[cpIndex] = updatedCp
              setCheckpoints(updatedCheckpoints)

              setUserAuthentications(prev => [
                ...prev,
                {
                  checkpoint_id: cp.index,
                  authentication_time: updatedCp.authenticatedAt
                }
              ])

              // 경품 당첨 조건 설정
              const remainder =
                user.course === 'A' ? (user.user_id % 6) + 2 : (user.user_id % 8) + 2

              if (cp.index === remainder && data.prizeMessage) {
                alert(data.prizeMessage) // 경품 당첨 알림
              } else {
                alert('CP 인증에 성공하였습니다.\n아쉽지만 경품은 다음 기회에')
              }
            } else {
              alert(`인증 실패: ${data.message}`)
            }
          } catch (error) {
            alert('오류가 발생했습니다.')
          }
        }
      } else {
        alert('인증 실패하였습니다.')
      }
    } catch (error: any) {
      if (error.response) {
        alert(`인증 실패: ${error.response.data.message || '알 수 없는 오류'}`)
      } else if (error.request) {
        alert('서버와의 연결이 끊겼습니다. 인터넷 연결을 확인해주세요.')
      } else {
        alert('인증 요청 중 오류가 발생했습니다.')
      }
      console.error('인증 요청 오류:', error)
    } finally {
      setShowQrScanner(false)
      hasScannedRef.current = false
    }
  }

  // * QR 스캔 오류 핸들러
  const handleScanError = (errorMessage: string) => {
    console.error('QR Scan Error:', errorMessage)
    alert(`QR 스캔 중 오류가 발생했습니다: ${errorMessage}`)
    setShowQrScanner(false)
    hasScannedRef.current = false
  }

  // * QR 스캐너 닫기 핸들러
  // const handleCloseScanner = () => {
  //   setShowQrScanner(false)
  //   hasScannedRef.current = false
  // }

  return (
    <div style={{position: 'relative', width: '100%', height: '100%'}}>
      <div
        ref={mapContainerRef}
        style={{
          width: '100%',
          height: '100%'
        }}
      />

      <div
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 1000
        }}>
        {/* SOS 버튼 */}
        <button
          onClick={() => (window.location.href = 'tel:010-3404-7734')}
          style={{
            width: '70px',
            height: '60px',
            borderRadius: '50%',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <img src={sosImage} alt="SOS" style={{width: '100%', height: '100%'}} />
        </button>
      </div>

      {/* QR 인증 버튼 */}
      <button
        onClick={handleQrAuthClick}
        style={{
          position: 'absolute',
          bottom: '30px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '80px',
          height: '80px',
          borderRadius: '50%',
          backgroundColor: '#007BFF',
          border: 'none',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999 // Increase this value
        }}>
        <FaQrcode size={40} color="white" />
      </button>

      {/* QR 스캐너 컴포넌트 */}
      {showQrScanner && (
        <QrScanner
          onScanSuccess={handleScanSuccess}
          onScanError={handleScanError}
          onClose={handleCloseScanner}
        />
      )}

      {/* 행사 종료 모달 */}
      <Modal
        isOpen={showCompletionModal}
        onRequestClose={() => setShowCompletionModal(false)}
        contentLabel="행사 종료"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
          }
        }}>
        <h2>모든 CP 인증을 완료했습니다.</h2>
        <p>경품 추첨 결과:</p>
        {prizeMessage ? (
          <ul>
            <li>{prizeMessage}</li>
            <p>축하드립니다!</p>
            <p>어썸홀리데이 운영부스에 들러서 경품을 수령해 주세요.</p>
          </ul>
        ) : (
          <p>아쉽지만 추가 경품은 당첨되지 않았습니다.</p>
        )}
        <button onClick={() => setShowCompletionModal(false)}>닫기</button>
      </Modal>

      {/* SOS 전송 확인 모달 */}
      <Modal
        isOpen={showSOSModal}
        onRequestClose={handleCancelSOS}
        contentLabel="SOS 전송 확인"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
          }
        }}>
        <h2>SOS 전송 확인</h2>
        <p>현재 위치를 전송하여 응급 상황을 알리겠습니까?</p>
        <button onClick={handleConfirmSOS} disabled={isSendingSOS}>
          {isSendingSOS ? '전송 중...' : '전송'}
        </button>
        <button onClick={handleCancelSOS}>취소</button>
      </Modal>
    </div>
  )
}

export default Map

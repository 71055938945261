import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import {Navigation, Pagination} from 'swiper/modules' // 최신 Swiper 모듈에서 Navigation, Pagination 가져오기

const Onboarding: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const navigate = useNavigate()

  const handleSlideChange = (swiper: any) => {
    setCurrentStep(swiper.activeIndex + 1)
  }

  const handleCompleteOnboarding = () => {
    // 온보딩 완료 후 메인 페이지로 이동
    navigate('/map')
  }

  return (
    <div style={styles.container}>
      <Swiper
        onSlideChange={handleSlideChange}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{clickable: true}}
        modules={[Navigation, Pagination]}
        className="onboarding-swiper"
        style={styles.swiper} // Swiper 높이를 명확히 지정
      >
        <SwiperSlide style={styles.slide}>
          <div style={styles.slideContent}>온보딩 화면 1</div>
        </SwiperSlide>
        <SwiperSlide style={styles.slide}>
          <div style={styles.slideContent}>온보딩 화면 2</div>
        </SwiperSlide>
        <SwiperSlide style={styles.slide}>
          <div style={styles.slideContent}>온보딩 화면 3</div>
        </SwiperSlide>
        <SwiperSlide style={styles.slide}>
          <div style={styles.slideContent}>온보딩 화면 4</div>
        </SwiperSlide>
        <SwiperSlide style={styles.slide}>
          <div style={styles.slideContent}>온보딩 화면 5</div>
        </SwiperSlide>
        <SwiperSlide style={styles.slide}>
          <div style={styles.slideContent}>온보딩 화면 6</div>
        </SwiperSlide>
        <SwiperSlide style={styles.slide}>
          <div style={styles.slideContent}>
            온보딩 화면 7
            <div style={styles.buttonContainer}>
              <button onClick={handleCompleteOnboarding} style={styles.completeButton}>
                시작하기
              </button>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div style={styles.progress}>{currentStep} / 7</div>
    </div>
  )
}

const styles = {
  container: {
    width: '100%',
    height: '100vh', // 전체 높이 설정
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f0f0f0' // 배경을 살짝 추가하여 뷰를 더 돋보이게 만듦
  },
  swiper: {
    width: '100%',
    height: '80vh' // Swiper의 명확한 높이 지정
  },
  slide: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.5rem',
    fontWeight: 'bold' as 'bold',
    backgroundColor: '#ffffff', // 각 슬라이드마다 배경색 추가
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    textAlign: 'center' as 'center',
    position: 'relative' as 'relative'
  },
  slideContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  progress: {
    marginTop: '20px',
    fontSize: '1.2rem',
    color: '#333'
  },
  buttonContainer: {
    position: 'absolute' as 'absolute',
    bottom: '30px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  completeButton: {
    padding: '10px 20px',
    fontSize: '1rem',
    color: '#fff',
    backgroundColor: '#007BFF',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    zIndex: 10 // 버튼이 가려지지 않도록 z-index 추가
  }
}

export default Onboarding
